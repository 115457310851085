<template>
  <div class="air-slider-captcha">
    <a-popover
      trigger="contextMenu"
      placement="top"
      overlayClassName="air-slider-captcha-popover"
      v-model="visible"
      @visibleChange="onVisibleChange"
    >
      <div
        ref="track"
        :class="{'slider-track': true, 'slider-track-lg': size === 'large', 'slider-track-sm': size === 'sm'}"
        @touchmove.prevent="onDragging"
        @touchend="onTouchEnd"
        @mouseenter="onEnter"
        @mousemove.prevent="onDragging"
        @mouseup="onDragEnd"
        @mouseleave="onLeave"
      >
        <div class="slider-progress"
          :style="{
            width: status === 'success' ? '100%' : progress + 'px'
          }"
        ></div>
        <div class="slider-text">{{ tip }}</div>
        <div
          :class="[
            'slider-bar',
            status
          ]"
          :style="{
            left: progress + 'px'
          }"
          @mousedown.prevent="onDragStart"
          @touchstart="onTouchStart"
        >
          <a-icon v-show="status === 'default'" type="double-right" />
          <a-icon v-show="status === 'success'" type="check-circle" />
          <a-icon v-show="status === 'loading'" type="loading" />
        </div>
      </div>
      <div class="slider-box" slot="content"
        @touchmove.prevent="onDragging"
        @touchend="onTouchEnd"
        @mouseenter="onEnter"
        @mousemove.prevent="onDragging"
        @mouseup="onDragEnd"
        @mouseleave="onLeave"
      >
        <div class="slider-bg"
          :style="{
            backgroundImage: `url(${bgImage})`
          }"
        >
          <div v-show="loading" class="loading">
            <a-icon type="loading" />
          </div>
          <div class="slider-block"
            :style="{
              left: progress * scale + 'px',
              backgroundImage: `url(${blockImage})`
            }"
            @mousedown.prevent="onSliderDragStart"
            @touchstart="onSliderTouchStart"
          ></div>
        </div>
        <div class="slider-refresh"
          @click="onRefresh"
        >
          <a-icon type="sync" />
        </div>
      </div>
    </a-popover>
  </div>
</template>

<script>
// 一个兼听函数，用于保证页面中存在多个滑块验证码时，状态保持一致
let captchaBg = ''
const captchaImage = {
  bg: '',
  block: '',
  loading: false, // 页面有多个验证码时，记录正在请求状态，避免多次请求
  list: []
}

Object.defineProperty(captchaImage, 'bg', {
  get () {
    return captchaBg
  },
  set (newValue) {
    captchaBg = newValue
    captchaImage.list.forEach(function (cb) {
      cb(newValue, captchaImage.block)
    })
  }
})

export default {
  name: 'air-slider-captcha',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * 验证码值
     */
    value: Boolean,
    /**
     * 验证码校验方法 PromiseFunction() resolve(value): 获取成功, reject: 获取失败
     */
    fetchCatpcha: {
      type: Function,
      required: true
    },
    /**
     * 验证码校验方法 PromiseFunction(value) resolve: 校验成功, reject: 校验失败
     */
    validator: {
      type: Function,
      required: true
    },
    /**
     * 失败多少次后刷新验证码
     */
    maxFailCount: {
      type: Number,
      default: 3
    },
    /**
     * 默认提示文案
     */
    defaultText: {
      type: String,
      default: '拖动滑块,完成拼图'
    },
    /**
     * 成功提示文案
     */
    successText: {
      type: String,
      default: '验证通过'
    },
    /**
     * 失败提示文案
     */
    failText: {
      type: String,
      default: '验证失败'
    },
    /**
     * 输入框提示
     */
    placeholder: String,
    /**
     * 输入框大小
     * @values default, small, large
     */
    size: {
      type: String,
      default: 'default'
    },
    bgWidth: {
      type: Number,
      default: 310
    },
    blockWidth: {
      type: Number,
      default: 69
    },
    /**
     * 初始化时是否弹出
     */
    showDefault: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      bgImage: '',
      blockImage: '',
      status: 'default',
      progress: 0,
      scale: 1,
      visible: false,
      failCount: 0,
      loading: false,
      tip: ''
    }
  },
  created () {
    /**
     * 校验回调
     *
     * @event change
     * @param { Boolean } value - 校验结果 { Boolean }
     */
    this.$emit('change', false)
    const that = this

    // 保证验证码刷新时，页面上所有的验证码都刷新
    captchaImage.list.push(function (bg, block) {
      that.bgImage = bg
      that.blockImage = block
      that.loading = false
      that.status = 'default'
      that.$emit('change', false)

      setTimeout(() => {
        if (!that.enter) {
          that.visible = false
        }
      }, 300)
    })
  },
  mounted () {
    // 计算滑块最大移动距离，以图片为基准，左右保留一定间距
    const progressWidth = this.$refs.track.clientWidth
    const { bgWidth, blockWidth } = this
    this.startPosition = this.progress = 15
    // bugfix: 滑块在浮层中时，取不到滑块宽度，设置scale为0.1保证第一次滑动时重新计算
    const scale = progressWidth ? this.scale = (bgWidth / progressWidth).toFixed(2) : 0.1
    this.maxWidth = (bgWidth - blockWidth) / scale - 10
    this.tip = this.defaultText
    this.failIndex = 0

    if (this.showDefault) {
      this.visible = true
    }

    // 初始请求验证码，让用户可以更快能移动滑块
    this.fecth()
  },
  methods: {
    fecth (cb) {
      const { fetchCatpcha } = this
      this.loading = true
      this.status = 'loading'

      // 如果已有其他验证码图片在请求，就等响应结果
      if (captchaImage.loading) {
        return
      }

      if (typeof fetchCatpcha === 'function') {
        const prom = fetchCatpcha()
        captchaImage.loading = true

        if (prom instanceof Promise) {
          prom.then(image => {
            captchaImage.block = image.block
            captchaImage.bg = image.bg
            captchaImage.loading = false
          }).catch(err => {
            this.$message.error(err.message)
            captchaImage.loading = false
          })
        } else {
          throw new Error('fetchCatpcha must be a function return Promise')
        }
      } else {
        throw new Error('fetchCatpcha must required')
      }
    },
    validate () {
      const { progress, startPosition, scale, validator, successText, failText, maxFailCount, maxWidth } = this
      this.status = 'loading'

      if (typeof validator === 'function') {
        const prom = validator(progress * scale)

        if (prom instanceof Promise) {
          prom.then(() => {
            this.tip = successText
            this.status = 'success'
            this.visible = false
            this.progress = maxWidth
            this.enter = false
            this.$emit('change', true)
          }).catch(err => {
            this.tip = failText
            this.status = 'default'
            this.progress = startPosition
            this.failIndex += 1
            this.$message.error(err.message)
            this.$emit('change', false)

            if (this.failIndex === maxFailCount) {
              this.fecth()
              this.failIndex = 0
            } else {
              if (!this.enter) {
                this.leaveTimer = setTimeout(() => {
                  this.visible = false
                  this.leaveTimer = null
                }, 1000)
              }
            }
          })
        } else {
          throw new Error('validator must be a function return Promise')
        }
      } else {
        throw new Error('validator must required')
      }
    },
    onSliderTouchStart (e) {
      this.onEnter()
      this.onDragStart(e, 'popover')
    },
    onSliderDragStart (e) {
      this.onDragStart(e, 'popover')
    },
    onTouchStart (e) {
      this.onEnter()
      this.onDragStart(e)
    },
    onDragStart (e, target = 'track') {
      const { bgImage, status, scale } = this

      if (scale === 0.1) {
        // 计算滑块最大移动距离，以图片为基准，左右保留一定间距
        const progressWidth = this.$refs.track.clientWidth
        const { bgWidth, blockWidth } = this
        const s = this.scale = (bgWidth / progressWidth).toFixed(2)
        this.maxWidth = (bgWidth - blockWidth) / s - 10
      }

      this.target = target

      if (!bgImage || status === 'success') {
        return
      }

      this.offsetX = e.clientX || e.touches[0].clientX
      this.moving = true
    },
    onDragging (e) {
      const { visible, moving, offsetX, maxWidth, progress, scale, target } = this

      if (!visible) {
        return
      }

      if (moving) {
        const clientX = e.clientX || e.touches[0].clientX
        const offset = clientX - offsetX
        this.offsetX = clientX
        let left = progress + (offset / (target === 'popover' ? scale : 1))

        if (left <= 15) {
          left = 15
        }

        if (left >= maxWidth) {
          left = maxWidth
        }

        this.progress = left
      }
    },
    onDragEnd () {
      const { progress, status } = this
      this.moving = false

      if (progress === 15 || status === 'success') {
        return
      }

      this.validate()
    },
    onTouchEnd () {
      this.onDragEnd()
      this.onLeave()
    },
    onEnter () {
      const { enterTimer, leaveTimer, status } = this

      if (leaveTimer) {
        clearTimeout(leaveTimer)
        this.leaveTimer = null
      }

      if (enterTimer) {
        return
      }

      if (status === 'success') {
        return
      }

      this.enterTimer = setTimeout(() => {
        this.enter = true
        this.visible = true
        this.enterTimer = null
      }, 200)
    },
    onLeave () {
      const { enterTimer, leaveTimer, progress, status } = this
      this.enter = false

      if (status === 'loading') {
        return
      }

      if (enterTimer) {
        clearTimeout(enterTimer)
        this.enterTimer = null
      }

      if (leaveTimer) {
        return
      }

      if (progress > 15 && status === 'default') {
        this.onDragEnd()
      } else {
        this.leaveTimer = setTimeout(() => {
          this.visible = false
          this.leaveTimer = null
        }, 300)
      }
    },
    onRefresh () {
      this.tip = this.defaultText
      this.status = 'default'
      this.progress = this.startPosition
      this.fecth()
    },
    onVisibleChange (visible) {
      if (!visible) {
        this.moving = false
      }
    }
  }
}
</script>
