import { Popover, Icon, message } from 'ant-design-vue'
import SliderCaptcha from './SliderCaptcha'

SliderCaptcha.install = (Vue) => {
  Vue.component(SliderCaptcha.name, SliderCaptcha)
  Vue.use(Popover)
  Vue.use(Icon)
  Vue.prototype.$message = message
}

export default SliderCaptcha
