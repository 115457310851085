const tabColumn = 4
const rowColumn = 10

export default {
  name: 'air-flight-city-selector',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * 指定当前选择的城市Code
     */
    value: String,
    /**
     * 城市列表数据源
     */
    dataSource: {
      type: Array,
      default: function () {
        return []
      },
      required: true
    },
    /**
     * 输入框提示
     */
    placeholder: String,
    /**
     * 输入框大小
     * @values default, small, large
     */
    size: {
      type: String,
      default: 'default'
    },
    /**
     * 弹出框位置
     * @values top, right, bottom, left, topLeft, topRight, rightTop, rightBottom, bottomLeft, bottomRight, leftTop, leftBottom
     */
    placement: {
      type: String,
      default: 'bottom'
    },
    /**
     * 是否展开选择框
     */
    visible: {
      type: Boolean,
      default: false
    },
    /**
     * 设置 FlightCitySelector 的模式
     * @values all, select, autoComplete
     */
    mode: {
      type: String,
      default: 'all'
    },
    /**
     * 搜索不到航点时显示的内容
     */
    notFoundContent: {
      type: String,
      default: '暂时不支持该地点'
    }
  },
  data () {
    return {
      code: '',
      serachResult: [],
      popoverVisible: false,
      group: [],
      labelGroup: [],
      show: false,
      open: false,
      current: 0
    }
  },
  watch: {
    visible (newValue) {
      if (typeof newValue === 'undefined') {
        return
      }

      if (this.mode === 'autoComplete') {
        this.open = newValue
      } else {
        this.show = newValue
      }
    }
  },
  created () {
    this.$watch('dataSource', (newValue) => {
      if (!newValue) {
        return
      }

      // 将数据源解析为方便select模式渲染的格式
      // 按字母分组
      const len = newValue.length
      let item
      let letter
      const letterGroup = {}
      const hotGroup = []
      const otherGroup = []

      for (let i = 0; i < len; i++) {
        item = newValue[i]

        if (item.other) {
          otherGroup.push(item)
          continue
        }

        if (item.num) {
          hotGroup.push(item)
        }

        letter = item.letter && item.letter.toLocaleUpperCase()

        if (!letter) {
          continue
        }

        if (typeof letterGroup[letter] === 'undefined') {
          letterGroup[letter] = {
            label: letter,
            value: []
          }
        }

        letterGroup[letter].value.push(item)
      }

      hotGroup.sort((a, b) => a.num - b.num)

      // 平分tab
      let groupLen
      const letters = []
      let row
      let rowsCount = 0

      for (letter in letterGroup) {
        groupLen = letterGroup[letter].value.length

        if (groupLen) {
          row = Math.ceil(groupLen / rowColumn)

          rowsCount += row
          letters.push({
            row,
            letter
          })
        }
      }

      letters.sort((a, b) => a.letter.charCodeAt() - b.letter.charCodeAt())

      // 将字母平均分到各个列
      const avgRow = Math.floor(rowsCount / tabColumn)
      const remain = rowsCount % tabColumn
      const columns = []

      for (let j = 0; j < tabColumn - 1; j++) {
        columns.push(j < remain ? avgRow + 1 : avgRow)
      }

      columns.push(Number.MAX_VALUE)

      const labelGroup = []
      let labelItem = []
      let count = 0
      row = columns.shift()

      letters.forEach((item, idx) => {
        count += item.row

        if (count < row) {
          labelItem.push(item.letter)
        } else {
          row = columns.shift()
          count = 0
          labelItem.push(item.letter)
          labelGroup.push({
            label: labelItem.join(''),
            value: [...labelItem]
          })
          labelItem = []
        }
      })

      if (labelItem.length) {
        labelGroup.push({
          label: labelItem.join(''),
          value: [...labelItem]
        })
      }

      if (hotGroup.length) {
        letterGroup.hot = {
          value: hotGroup
        }
        labelGroup.unshift({
          label: '热门',
          value: ['hot']
        })
      }

      if (otherGroup.length) {
        letterGroup.other = {
          value: otherGroup
        }
        labelGroup.push({
          label: '港澳台及其他国家城市',
          value: ['other']
        })
      }

      this.group = letterGroup
      this.labelGroup = labelGroup
      this.serachResult = newValue
    }, {
      immediate: true
    })

    this.$watch('value', (newValue) => {
      if (newValue) {
        this.code = newValue
      }
    }, {
      immediate: true
    })
  },
  render (h) {
    const { $props, show } = this
    const props = {
      props: {
        trigger: 'contextmenu',
        placement: $props.placement,
        visible: show,
        overlayClassName: 'air-city-popover'
      },
      on: {
        visibleChange: this.onVisibleChange
      }
    }

    return (
      <div class="air-flight-city-selector">
        <a-popover {...props}>
          { this.renderAutoComplete(h) }
          <div class="panel-box" slot="content">
            { this.renderContent(h) }
          </div>
        </a-popover>
      </div>
    )
  },
  methods: {
    renderAutoComplete (h) {
      const { serachResult, code, open, $props, $scopedSlots } = this
      const props = {
        props: {
          dataSource: serachResult,
          dropdownClassName: 'air-city-search-dropdown',
          value: code,
          notFoundContent: $props.notFoundContent,
          placeholder: $props.placeholder,
          size: $props.size,
          open: open,
          optionLabelProp: 'title'
        },
        on: {
          focus: this.onFocus,
          blur: this.onBlur,
          change: this.onChange,
          search: this.onSearch
        },
        scopedSlots: $scopedSlots
      }
      const inputProps = {
        scopedSlots: $scopedSlots
      }

      const options = serachResult.map(item => {
        return (
          <a-select-option key={item.code} value={item.code} title={item.city}>
            <span class="city">{ item.city }</span>
            <div class="panel-box" slot="content">
              <span class="code">{ item.code }</span>
            </div>
          </a-select-option>
        )
      })

      return (
        <a-auto-complete {...props}>
          <template slot="dataSource">
            { options }
          </template>
          <a-input {...inputProps}></a-input>
        </a-auto-complete>
      )
    },
    renderContent (h) {
      const { labelGroup, group, current, code } = this
      const that = this
      let children

      if (labelGroup.length) {
        const tabHeader = labelGroup.map((label, idx) => {
          const props = {
            class: { active: current === idx },
            key: label.label,
            on: {
              click: function () {
                that.onTabChange(idx)
              }
            }
          }
          return (
            <li {...props}>
              { label.label }
            </li>
          )
        })
        const tabBody = labelGroup.map((label, idx) => {
          const props = {
            class: {
              active: current === idx,
              group: true
            },
            key: label.label
          }
          const row = label.value.map(letter => {
            const dt = group[letter].label
              ? <div class="letter">{ group[letter].label }</div>
              : null
            const ulProps = {
              class: {
                'city-list': true,
                'city-list-no-label': !group[letter].label
              }
            }
            const li = group[letter].value.map(item => {
              const props = {
                class: { active: item.code === code, 'item-long': item.city.length > 6 },
                key: item.code,
                on: {
                  click: function () {
                    that.onSelect(item)
                  }
                }
              }

              return <li {...props}>{ item.city }</li>
            })

            return (
              <div class="row" key={letter}>
                { dt }
                <ul {...ulProps}>
                  { li }
                </ul>
              </div>
            )
          })

          return (
            <div {...props}>
              { row }
            </div>
          )
        })

        children = (
          <div>
            <div class="tab-header">
              <ul>
                { tabHeader }
              </ul>
            </div>
            <div class="tab-body">
              { tabBody }
            </div>
          </div>
        )
      } else {
        children = (
          <div class="air-loading">
            <a-spin size="large"></a-spin>
          </div>
        )
      }

      return (
        <div>
          { children }
        </div>
      )
    },
    onChange (value) {
      const { mode, dataSource } = this
      value = value.trim()

      if (mode === 'select') {
        return
      }

      if (value) {
        this.show = false
        this.open = true
      } else {
        if (mode !== 'autoComplete') {
          this.show = true
          this.open = false
        } else {
          this.open = false
        }

        this.change('', {})
      }

      this.code = value
      const city = dataSource.find((item) => item.code === value.toLocaleUpperCase() || item.city === 'value')

      if (city) {
        this.change(value, city)
      }
    },
    onSelect (value) {
      this.change(value.code, value)
    },
    onTabChange (idx) {
      this.current = idx
    },
    onSearch (value) {
      this.serachResult = this.dataSource.filter(item => {
        const str = `${item.code.toLocaleLowerCase()} ${item.pinyin.toLocaleLowerCase()} ${item.city}`

        return str.indexOf(value.toLocaleLowerCase()) > -1
      })
    },
    onFocus () {
      if (this.mode !== 'autoComplete') {
        this.open = false
        this.show = true
      } else if (this.code) {
        this.open = true
        this.show = false
      }
    },
    onBlur () {
      if (!this.show) {
        this.close()
      }
    },
    onVisibleChange (visible) {
      if (!visible) {
        this.close()
      }

      this.show = visible
    },
    change (value, city) {
      this.code = city.code
      /**
       * 选择航点城市时，调用此函数
       *
       * @event change
       * @param { string } value - 航点城市Code {string}
       * @param { object } city - 航点城市对象，包含城市名等信息 {object}
       */
      this.$emit('change', value, city)
      this.close()
    },
    close () {
      this.open = false
      this.show = false
      /**
       * 选择框关闭事件
       *
       * @event close
       */
      this.$emit('close')
    }
  }
}
