var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"air-slider-captcha"},[_c('a-popover',{attrs:{"trigger":"contextMenu","placement":"top","overlayClassName":"air-slider-captcha-popover"},on:{"visibleChange":_vm.onVisibleChange},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{ref:"track",class:{'slider-track': true, 'slider-track-lg': _vm.size === 'large', 'slider-track-sm': _vm.size === 'sm'},on:{"touchmove":function($event){$event.preventDefault();return _vm.onDragging($event)},"touchend":_vm.onTouchEnd,"mouseenter":_vm.onEnter,"mousemove":function($event){$event.preventDefault();return _vm.onDragging($event)},"mouseup":_vm.onDragEnd,"mouseleave":_vm.onLeave}},[_c('div',{staticClass:"slider-progress",style:({
          width: _vm.status === 'success' ? '100%' : _vm.progress + 'px'
        })}),_c('div',{staticClass:"slider-text"},[_vm._v(_vm._s(_vm.tip))]),_c('div',{class:[
          'slider-bar',
          _vm.status
        ],style:({
          left: _vm.progress + 'px'
        }),on:{"mousedown":function($event){$event.preventDefault();return _vm.onDragStart($event)},"touchstart":_vm.onTouchStart}},[_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.status === 'default'),expression:"status === 'default'"}],attrs:{"type":"double-right"}}),_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.status === 'success'),expression:"status === 'success'"}],attrs:{"type":"check-circle"}}),_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.status === 'loading'),expression:"status === 'loading'"}],attrs:{"type":"loading"}})],1)]),_c('div',{staticClass:"slider-box",attrs:{"slot":"content"},on:{"touchmove":function($event){$event.preventDefault();return _vm.onDragging($event)},"touchend":_vm.onTouchEnd,"mouseenter":_vm.onEnter,"mousemove":function($event){$event.preventDefault();return _vm.onDragging($event)},"mouseup":_vm.onDragEnd,"mouseleave":_vm.onLeave},slot:"content"},[_c('div',{staticClass:"slider-bg",style:({
          backgroundImage: ("url(" + _vm.bgImage + ")")
        })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loading"},[_c('a-icon',{attrs:{"type":"loading"}})],1),_c('div',{staticClass:"slider-block",style:({
            left: _vm.progress * _vm.scale + 'px',
            backgroundImage: ("url(" + _vm.blockImage + ")")
          }),on:{"mousedown":function($event){$event.preventDefault();return _vm.onSliderDragStart($event)},"touchstart":_vm.onSliderTouchStart}})]),_c('div',{staticClass:"slider-refresh",on:{"click":_vm.onRefresh}},[_c('a-icon',{attrs:{"type":"sync"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }