import { AutoComplete, Popover, Spin } from 'ant-design-vue'
import FlightCitySelector from './FlightCitySelector'

FlightCitySelector.install = (Vue) => {
  Vue.component(FlightCitySelector.name, FlightCitySelector)
  Vue.use(AutoComplete)
  Vue.use(Popover)
  Vue.use(Spin)
}

export default FlightCitySelector
